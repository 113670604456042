import Header from '../../../../components/common/header';
import VitaButton from '../../../../components/common/VitaButton';
import {BUTTON_TEXT, CONTACT_INFO} from '../../../../constants';
import DivideLine from '../../../../components/common/divideLine';

const ConfirmAddress = ({
  receivedAddress,
  handleClickConfirm,
  isConfirmedAddress,
  handleClickChangeAddress,
  handleClickBack,
}) => {
  return (
    <>
      <div className="bg-background">
        <Header isBackground />

        <div className="px-6 py-10">
          <div className="text-2xl font-semibold text-center leading-medium">
            <div className="text-black">XÁC NHẬN ĐỊA CHỈ</div>
            <div className="mt-4 text-primary">
              {`Quà tặng sẽ được giao về ${
                receivedAddress?.address ? receivedAddress?.address : ''
              }, ${receivedAddress?.wardName ? receivedAddress?.wardName : ''}, ${
                receivedAddress?.districtName ? receivedAddress?.districtName : ''
              }, ${receivedAddress?.provinceName ? receivedAddress?.provinceName : ''}`}
            </div>
          </div>

          <DivideLine className="mt-10 mb-6" />

          <div className="mb-10 font-semibold text-center text-primary leading-medium">
            <div className="text-[18px]">Cần được hỗ trợ liên hệ</div>
            <div className="text-[32px] underline">
              <a href={`tel:${CONTACT_INFO.PHONE_NUMBER}`}>{CONTACT_INFO.PHONE_NUMBER}</a>
            </div>
          </div>

          <div>
            <div className="mb-6">
              <VitaButton onClick={handleClickConfirm}>{BUTTON_TEXT.CONFIRM}</VitaButton>
            </div>

            {isConfirmedAddress === false && (
              <div>
                <VitaButton
                  className="px-8 max-[320px]:px-2 mb-6"
                  isOutlined
                  onClick={handleClickChangeAddress}
                >
                  {BUTTON_TEXT.CHANGE_GIFT_RECEIVING_ADDRESS}
                </VitaButton>
              </div>
            )}
            <div>
              <VitaButton isOutlined onClick={handleClickBack}>
                {BUTTON_TEXT.BACK}
              </VitaButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmAddress;
