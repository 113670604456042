import React from 'react';
import LogoVita from 'images/logo-single.png';

const PageNotFound = () => {
  return (
    <div className="h-screen bg-background flex justify-center items-center">
      <div className="flex flex-col gap-4 items-center">
        <div className="w-[180px]">
          <img src={LogoVita} alt="Logo Vita Dairy" />
        </div>
        <div className="text-xl">Không tìm thấy trang này</div>
      </div>
    </div>
  );
};

export default PageNotFound;
