import Carousel from '../common/carousel';

const Banners = ({handleLogin, banners}) => {
  return (
    <div className="relative">
      <div className="banners-slick overflow-x-hidden">
        <Carousel
          settings={{
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            // autoplay: false,
            autoplay: true,
            slide: 'ul',
          }}
        >
          {banners?.map((item, index) => {
            return (
              <div key={index} className="max-w-screen px-2">
                <img className="w-full" src={item?.src} alt={item?.alt || ''} />
              </div>
            );
          })}
        </Carousel>

        <button
          onClick={handleLogin}
          className="bg-white absolute left-1/2 -translate-x-1/2 bottom-3 px-5 py-2 rounded-[64px] text-primary font-semibold w-max leading-medium"
        >
          ĐĂNG KÝ / ĐĂNG NHẬP
        </button>
      </div>
    </div>
  );
};

export default Banners;
