import logoSingle from 'images/logo-single.png';
import logoBackground from 'images/logo-background.png';
import logoCalosure from 'images/logo-calosure.png';

const Header = ({isBackground = false, className = '', ...props}) => {
  return (
    <>
      {isBackground ? (
        <div className={className} {...props}>
          <img src={logoBackground} alt="Logo VitaDairy" />
        </div>
      ) : (
        <div className={`sticky top-0 bg-white w-full z-[100] ${className}`}>
          <div className="py-2 flex justify-center border-b-[1px] border-b-[#ECECEC]">
            <div className="max-w-[100px]">
              <img className="" src={logoSingle} alt="Logo VitaDairy" />
            </div>
            <div className="px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="40"
                viewBox="0 0 2 40"
                fill="none"
              >
                <path d="M0.785156 0V40" stroke="#E8E8E8" />
              </svg>
            </div>
            <div className="max-w-[100px]">
              <img className="" src={logoCalosure} alt="Logo Calosure America" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
