import {CODE_KEY} from 'constants/index';
import ROUTES from 'constants/routes';
import _ from 'lodash';
import {toast} from 'react-toastify';

export function handleLazyLoadError(err) {
  console.error(err);
  window.location.reload();
}

function convertDataError(error) {
  const {code = {}, message, status, Messages, messages} = error;

  if (message && message.search('Cannot read property') >= 0) {
    return {
      code: CODE_KEY.UNDEFINED,
      message: 'Truy vấn dữ liệu lỗi.',
      status,
    };
  }
  if (message && message.search('Network Error') >= 0) {
    return {
      code: CODE_KEY.ERROR_NETWORK,
      message: 'Không thể kết nối tới server.',
      status,
    };
  }

  if (Array.isArray(messages) && !_.isEmpty(messages)) {
    return {
      code,
      message: messages[0]?.content,
      status,
    };
  }

  if (Array.isArray(Messages) && !_.isEmpty(Messages)) {
    return {
      code,
      message: Messages[0]?.Content,
      status,
    };
  }

  return error;
}

export function handleErrorMessage(err) {
  const {response} = err;
  if (response?.data) {
    return convertDataError(response.data);
  }
  return convertDataError(err);
}

export function handleErrorResponse(error, callback) {
  if (callback) callback();

  if (error?.status !== CODE_KEY.UNAUTHORIZED_STATUS) {
    if (error?.data?.meta?.msg)
      toast.error(error.data.meta.msg, {autoClose: 2000, position: 'top-center'});
    else console.error('error from handleErrorResponse', error);
  }
}

export function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

export const showErrorToastFromResponse = (response) => {
  if (response?.code === 400) {
    toast.error(response?.message || 'Kiểm tra lại dữ liệu!', {
      autoClose: 2000,
      position: 'top-center',
    });
    return;
  }

  if (response?.code === 401) {
    toast.error(response?.message || 'Bạn không có quyền truy cập!', {
      autoClose: 2000,
      position: 'top-center',
    });
    return;
  }

  if (response?.code === 420) {
    toast.error(
      response?.message ||
        'Quà của bạn đã hết hàng trong kho. Vui lòng liên hệ với chúng tôi để được hỗ trợ.',
      {
        autoClose: 2000,
        position: 'top-center',
      }
    );
    return;
  }

  toast.error(response?.message || 'Hệ thống đang bảo trì, vui lòng thử lại sau.', {
    autoClose: 2000,
    position: 'top-center',
  });
};
