import IconCustomerService from 'images/icon/customerService.svg';
import IconPhone from 'images/icon/phone.svg';
import IconZalo from 'images/icon/zalo.svg';
import IconClose from 'images/icon/close.svg';
import IconUser from 'images/icon/user.svg';
import IconTruck from 'images/icon/truck.svg';
import IconChevronRight from 'images/icon/chevronRight.svg';
import IconGiftBox from 'images/icon/giftBox.svg';

export const SVG_ICON = {
  CUSTOMER_SERVICE: IconCustomerService,
  PHONE: IconPhone,
  ZALO: IconZalo,
  CLOSE: IconClose,
  USER: IconUser,
  TRUCK: IconTruck,
  CHEVRON_RIGHT: IconChevronRight,
  GIFT_BOX: IconGiftBox,
};
