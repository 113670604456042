import React, {useState} from 'react';
import {CONTACT_INFO} from 'constants/index';
import {SVG_ICON} from 'constants/icon';

const ContactButton = ({className = '', ...props}) => {
  const [isOpenSupport, setIsOpenSupport] = useState(null);

  return (
    <div className={`fixed right-4 bottom-14 z-50 ${className}`} {...props}>
      <>
        <div
          className={`mb-4 ${
            isOpenSupport
              ? 'animate-appear-in-up visible'
              : isOpenSupport === false
              ? 'animate-fade-in-down invisible'
              : 'invisible'
          }`}
        >
          <a href={CONTACT_INFO?.PHONE_NUMBER ? `tel:${CONTACT_INFO.PHONE_NUMBER}` : null}>
            <div className="p-3 w-12 h-12 rounded-full border-[1px] border-primary bg-white">
              <img src={SVG_ICON.PHONE} alt="phone icon" />
            </div>
          </a>
        </div>

        <div
          className={`mb-4 ${
            isOpenSupport
              ? 'animate-appear-in-up visible'
              : isOpenSupport === false
              ? 'animate-fade-in-down invisible'
              : 'invisible'
          }`}
        >
          <a href={CONTACT_INFO?.ZALO ? CONTACT_INFO?.ZALO : null} target="_blank" rel="noreferrer">
            <div className="p-3 w-12 h-12 rounded-full border-[1px] border-primary bg-white">
              <img src={SVG_ICON.ZALO} alt="zalo icon" />
            </div>
          </a>
        </div>
      </>

      <button
        onClick={() => setIsOpenSupport(!isOpenSupport)}
        className="p-3 w-12 h-12 rounded-full bg-primary shadow-blur20"
      >
        {isOpenSupport ? (
          <img src={SVG_ICON.CLOSE} alt="close icon" />
        ) : (
          <img src={SVG_ICON.CUSTOMER_SERVICE} alt="contact icon" />
        )}
      </button>
    </div>
  );
};

export default React.memo(ContactButton);
