import {STORAGE_KEY} from 'constants/index';
import {localStorageGetItem} from './storage';

export default function checkLoginStatus(callbackLoginTrue = null, callbackLoginFalse = null) {
  let token = localStorageGetItem(STORAGE_KEY.ACCESS_TOKEN);

  if (token) {
    if (callbackLoginTrue) callbackLoginTrue();
  } else {
    if (callbackLoginFalse) callbackLoginFalse();
  }
}
