import {getGiftsByUserWithCategoryIdApi} from 'apis/index';
import {LoginContext} from 'App';
import ContactButton from 'components/common/contactButton';
import Header from 'components/common/header';
import Banners from 'components/home/banners';
import FeatureBlock from 'components/home/featureBlock';
import GiftShortList from 'components/home/giftShortList';
import UserInfo from 'components/home/userInfo';
import {CATEGORY_ID, ErrorCode, LINK_PROMOTION} from 'constants/index';
import {BANNER_IMG, IMAGES_HOME, TYPE_GIFT, TYPE_OF_GIFT} from 'constants/info-homepage';
import ROUTES from 'constants/routes';
import {filter} from 'lodash';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './custom.scss';
import ErrorCodeComponent from './ErrorCodeComponent';
import {GIFT_EXCHANGE_STEP, TYPE_RESPONSE} from 'constants/info-giftpage';
import PhysicalConfirmAddress from 'pages/Gift/subPage/PhysicalConfirmAddress';
import Response from 'pages/Gift/subPage/Response';
import PhysicalConfirmAddressV2 from 'pages/Gift/subPage/PhysicalConfirmAddressV2';

const Home = () => {
  const {isLogin, userInfo, setIsLoading, checkBlockedScan} = useContext(LoginContext);
  const [banners, setBanners] = useState([]);
  const [dataGiftCanExchange, setDataGiftCanExchange] = useState(null);
  const [dataSpecialGift, setDataSpecialGift] = useState(null);
  // const [dataAllGift, setDataAllGift] = useState(null);
  const [dataHotGift, setDataHotGift] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorCode, setErrorCode] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const currentRoute = useRef(null);
  /*
  const getGiftCategoriesData = async () => {
    setIsLoading(true);
    try {
      const response = await getGiftCategoriesApi({size: 5}); //{size: 5}

      if (
        response?.meta?.status === API_SUCCESS_STATUS &&
        response?.response?.categories.length > 0
      ) {
        let categoriesData = response?.response?.categories;

        // let dataResAllGift = categoriesData.filter((item) =>
        //   CATEGORY_ID.ALL_GIFT.includes(item.id)
        // );

        let dataResSpecialGift = categoriesData.filter((item) =>
          CATEGORY_ID.SPECIAL_GIFT.includes(item.id)
        );

        let dataResHotGift = categoriesData.filter((item) =>
          CATEGORY_ID.HOT_GIFT.includes(item.id)
        );

        if (dataResSpecialGift?.length > 0) {
          let listGifts = [];
          dataResSpecialGift.forEach((cate) => {
            if (cate?.gifts?.length > 0) {
              listGifts = listGifts.concat(cate?.gifts);
            }
          });

          let specialGiftData = {
            // name: dataResSpecialGift?.name,
            name: TYPE_GIFT.SPECIAL_GIFT.TEXT,
            gifts: filter(listGifts, (gift) => {
              return gift?.type === TYPE_OF_GIFT.E_VOUCHER || gift?.type === TYPE_OF_GIFT.PHYSICAL;
            }),
            type: TYPE_GIFT.SPECIAL_GIFT.VALUE,
          };

          setDataSpecialGift(specialGiftData);
        } else {
          setDataSpecialGift(null);
        }
        
        // if (dataResAllGift) {
        //   let allGiftData = {
        //     name: dataResAllGift?.name,
        //     gifts: filter(dataResAllGift?.gifts, (gift) => {
        //       return gift?.type === TYPE_OF_GIFT.E_VOUCHER || gift?.type === TYPE_OF_GIFT.PHYSICAL;
        //     }),
        //     type: TYPE_GIFT.ALL_GIFT.VALUE,
        //   };

        //   setDataAllGift(allGiftData);
        // } else {
        //   setDataAllGift(null);
        // }


        if (dataResHotGift?.length > 0) {
          let listGifts = [];
          dataResHotGift.forEach((cate) => {
            if (cate?.gifts?.length > 0) {
              listGifts = listGifts.concat(cate?.gifts);
            }
          });

          let hotGiftData = {
            name: TYPE_GIFT.HOT_GIFT.TEXT,
            gifts: filter(listGifts, (gift) => {
              return gift?.type === TYPE_OF_GIFT.E_VOUCHER || gift?.type === TYPE_OF_GIFT.PHYSICAL;
            }),
            type: TYPE_GIFT.HOT_GIFT.VALUE,
          };

          setDataHotGift(hotGiftData);
        } else {
          setDataHotGift(null);
        }
      } else {
        setDataSpecialGift(null);
        // setDataAllGift(null);
        setDataHotGift(null);

        if (response?.meta?.error) {
          toast.error(response?.meta?.msg || 'Lấy thông tin danh sách quà tặng thất bại!', {
            autoClose: 2000,
            position: 'top-center',
          });
        } else
          toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
            autoClose: 2000,
            position: 'top-center',
          });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setDataSpecialGift(null);
      // setDataAllGift(null);
      setDataHotGift(null);

      if (error?.response) {
        handleErrorResponse(error.response);
      } else {
        console.error('error from getGiftCategoriesData', error);
        toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
    }
  };
*/

  const getHotGiftsByCategoryId = async () => {
    try {
      let listHotGiftCategoryIds = [...CATEGORY_ID.NEW_HOT_GIFT];

      const responseHotGifts = await Promise.all(
        listHotGiftCategoryIds.map((id) =>
          getGiftsByUserWithCategoryIdApi(id)
            .then((res) => {
              const data = res?.response?.data?.data;

              return data?.length > 0
                ? filter(data, (gift) => {
                    return (
                      (gift?.type === TYPE_OF_GIFT.E_VOUCHER ||
                        gift?.type === TYPE_OF_GIFT.PHYSICAL ||
                        gift?.type === TYPE_OF_GIFT.E_VOUCHER_V2) &&
                      gift?.status === 'ENABLED'
                    );
                  })
                : [];
            })
            .catch((err) => console.error(err))
        )
      );

      const listHotGiftsData = responseHotGifts?.flat()?.sort?.((a, b) => a?.point - b?.point);

      if (listHotGiftsData?.length > 0) {
        let dataResHotGifts = {
          name: TYPE_GIFT.HOT_GIFT.TEXT,
          gifts: listHotGiftsData,
          type: TYPE_GIFT.HOT_GIFT.VALUE,
        };
        setDataHotGift(dataResHotGifts);
      } else {
        setDataHotGift(null);
      }
    } catch (error) {
      setDataHotGift(null);
      console.error('error from getHotGiftsByCategoryId about Hot Gifts', error);
    }
  };

  const getSpecialGiftsByCategoryId = async () => {
    try {
      let listSpecialGiftCategoryIds = [...CATEGORY_ID.NEW_SPECIAL_GIFT];
      const responseSpecialGifts = await Promise.all(
        listSpecialGiftCategoryIds.map((id) =>
          getGiftsByUserWithCategoryIdApi(id)
            .then((res) => {
              const data = res?.response?.data?.data;

              return data?.length > 0
                ? filter(data, (gift) => {
                    return (
                      (gift?.type === TYPE_OF_GIFT.E_VOUCHER ||
                        gift?.type === TYPE_OF_GIFT.PHYSICAL ||
                        gift?.type === TYPE_OF_GIFT.E_VOUCHER_V2) &&
                      gift?.status === 'ENABLED'
                    );
                  })
                : [];
            })
            .catch((err) => console.error(err))
        )
      );
      const listSpecialGiftsData = responseSpecialGifts
        ?.flat()
        ?.sort?.((a, b) => a?.point - b?.point);

      if (listSpecialGiftsData?.length > 0) {
        let dataResSpecialGifts = {
          name: TYPE_GIFT.SPECIAL_GIFT.TEXT,
          gifts: listSpecialGiftsData,
          type: TYPE_GIFT.SPECIAL_GIFT.VALUE,
        };
        setDataSpecialGift(dataResSpecialGifts);
      } else {
        setDataSpecialGift(null);
      }
    } catch (error) {
      setDataSpecialGift(null);
      console.error('error from getSpecialGiftsByCategoryId about Special Gifts', error);
    }
  };

  const getGiftsByCategoryId = async () => {
    setIsLoading(true);
    try {
      await Promise.all([getHotGiftsByCategoryId(), getSpecialGiftsByCategoryId()]);
    } catch (error) {
      console.error('error from getGiftsByCategoryId ', error);
    }
    setIsLoading(false);
  };

  /*
  const getGiftsCanExchange = async () => {
    setIsLoading(true);
    try {
      const response = await getListGiftByUserApi({
        page: 1,
        limit: 5,
        types: [TYPE_OF_GIFT.PHYSICAL, TYPE_OF_GIFT.E_VOUCHER],
      });
      //  console.log('response from getListGiftByUserApi', response);
      if (response?.meta?.status === API_SUCCESS_STATUS && response?.response?.length > 0) {
        let dataResGiftCanExchange = {
          name: TYPE_GIFT.GIFT_CAN_EXCHANGE.TEXT,
          gifts: filter(response?.response, (gift) => {
            return gift?.type === TYPE_OF_GIFT.E_VOUCHER || gift?.type === TYPE_OF_GIFT.PHYSICAL;
          }),
          type: TYPE_GIFT.GIFT_CAN_EXCHANGE.VALUE,
        };

        setDataGiftCanExchange(dataResGiftCanExchange);
      } else {
        setDataGiftCanExchange(null);

        console.error('response error from getGiftCategoriesData', response);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setDataGiftCanExchange(null);

      console.error('error from getGiftCategoriesData', error);
    }
  };
*/
  const getGiftsByUser = async () => {
    let listCategoryIds = [...CATEGORY_ID.NEW_HOT_GIFT];
    setIsLoading(true);
    try {
      const response = await Promise.all(
        listCategoryIds.map((id) =>
          getGiftsByUserWithCategoryIdApi(id)
            .then((res) => {
              const data = res?.response?.data?.data;

              return data?.length > 0
                ? filter(data, (gift) => {
                    return (
                      (gift?.type === TYPE_OF_GIFT.E_VOUCHER ||
                        gift?.type === TYPE_OF_GIFT.PHYSICAL ||
                        gift?.type === TYPE_OF_GIFT.E_VOUCHER_V2) &&
                      gift?.status === 'ENABLED'
                    );
                  })
                : [];
            })
            .catch((err) => console.error(err))
        )
      );

      const listData = response
        ?.flat()
        ?.filter((gift) => gift?.point <= userInfo?.point)
        ?.sort?.((a, b) => a?.point - b?.point);

      if (listData?.length > 0) {
        let dataResGiftCanExchange = {
          name: TYPE_GIFT.GIFT_CAN_EXCHANGE.TEXT,
          gifts: listData,
          type: TYPE_GIFT.GIFT_CAN_EXCHANGE.VALUE,
        };
        setDataGiftCanExchange(dataResGiftCanExchange);
      } else {
        setDataGiftCanExchange(null);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setDataGiftCanExchange(null);
      console.error('error from getGiftsByUser', error);
    }
  };

  useEffect(() => {
    if (isLogin && userInfo) {
      setBanners([]);
      // getGiftsCanExchange();
      getGiftsByUser();
    } else {
      setBanners(BANNER_IMG);
      if (dataGiftCanExchange) setDataGiftCanExchange(null);
    }
  }, [isLogin, userInfo]);

  useEffect(() => {
    if (checkBlockedScan && checkBlockedScan?.blockedScan) {
      if (location?.state?.blockedScanError) setErrorCode(location?.state?.blockedScanError);
    }
  }, [checkBlockedScan]);

  useEffect(() => {
    // getGiftCategoriesData();
    getGiftsByCategoryId();
  }, []);

  const featureContent = [
    {
      text: 'Tự tích xu',
      src: IMAGES_HOME.SVGCoin,
      onClick: (e) => {
        e.preventDefault();

        if (isLogin) {
          if (checkBlockedScan?.blockedScan) {
            setErrorCode(ErrorCode.BlockedEarningCoin);
          } else {
            setShowAddress(true);

            // navigate(ROUTES.EARNING_COIN);
            currentRoute.current = ROUTES.EARNING_COIN;
          }
        } else navigate(ROUTES.LOGIN, {state: {routeInProgress: ROUTES.EARNING_COIN}});
      },
      isShow: true,
    },
    {
      text: 'Nhờ tích xu',
      src: IMAGES_HOME.SVGSavingCoin,
      onClick: (e) => {
        e.preventDefault();

        if (isLogin) {
          setShowAddress(true);
          // navigate(ROUTES.SUPPORT_EARNING_COIN);
          currentRoute.current = ROUTES.SUPPORT_EARNING_COIN;
        } else navigate(ROUTES.LOGIN, {state: {routeInProgress: ROUTES.SUPPORT_EARNING_COIN}});
        // {
        //   if (checkBlockedScan?.blockedScan) {
        //     setErrorCode(ErrorCode.BlockedSupportEarningCoin);
        //   } else {
        //     navigate(ROUTES.SUPPORT_EARNING_COIN);
        //   }
        // }
      },
      isShow: true,
    },
    {
      text: 'Khuyến mại',
      src: IMAGES_HOME.SVGPromotion,
      onClick: (e) => {
        e.preventDefault();
        window.open(LINK_PROMOTION, '_blank', 'noopener, noreferrer');
      },
      isShow: isLogin,
    },
    {
      text: 'Quà đã đổi',
      src: IMAGES_HOME.SVGExchangedGifts,
      onClick: (e) => {
        e.preventDefault();
        if (isLogin) navigate(ROUTES.EXCHANGED_GIFT);
        else navigate(ROUTES.LOGIN, {state: {routeInProgress: ROUTES.EXCHANGED_GIFT}});
      },
      isShow: isLogin,
    },
  ];

  const handleLogin = useCallback(() => {
    navigate(ROUTES.LOGIN);
  }, []);

  const handleDetailUserInfo = useCallback(() => {
    navigate(ROUTES.USER_INFO);
  }, []);

  const renderBody = () => {
    switch (errorCode) {
      case ErrorCode.BlockedEarningCoin:
        return (
          <>
            <Header isBackground={true} />
            <ErrorCodeComponent errorCode={errorCode} setErrorCode={setErrorCode} />
          </>
        );
      // case ErrorCode.BlockedSupportEarningCoin:
      //   return (
      //     <>
      //       <Header isBackground={true} />
      //       <ErrorCodeComponent errorCode={errorCode} setErrorCode={setErrorCode} />
      //     </>
      //   );
      case null:
        return (
          <>
            <div className="bg-background">
              <Header />
              <div className={`pt-4 ${isLogin ? 'px-4' : 'px-2'}`}>
                {isLogin ? (
                  <UserInfo userInfo={userInfo} handleDetailUserInfo={handleDetailUserInfo} />
                ) : (
                  <Banners banners={banners} handleLogin={handleLogin} />
                )}
              </div>

              <div className="px-4 pb-4 text-default">
                <div className="grid grid-cols-2 gap-y-2 gap-x-4 mt-6">
                  {featureContent.map((item, index) => (
                    <FeatureBlock key={index} info={item} />
                  ))}
                </div>

                {dataGiftCanExchange?.gifts?.length > 0 && isLogin && (
                  <div className="mt-6">
                    <GiftShortList data={dataGiftCanExchange} />
                  </div>
                )}

                {dataHotGift?.gifts?.length > 0 && (
                  <div className="mt-6">
                    <GiftShortList data={dataHotGift} />
                  </div>
                )}

                {dataSpecialGift?.gifts?.length > 0 && (
                  <div className="mt-6">
                    <GiftShortList data={dataSpecialGift} />
                  </div>
                )}

                {/* {dataAllGift?.gifts?.length > 0 && (
                  <div className="mt-6">
                    <GiftShortList data={dataAllGift} />
                  </div>
                )} */}
              </div>
            </div>

            <ContactButton />
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      {showAddress ? (
        <Address
          handleSuccess={() => {
            if (currentRoute.current) navigate(currentRoute.current);
          }}
        />
      ) : (
        renderBody()
      )}
    </>
  );
};

export default Home;

const Address = ({handleSuccess}) => {
  const navigate = useNavigate();
  const [giftExchangeStep, setGiftExchangeStep] = useState(
    GIFT_EXCHANGE_STEP.PHYSICAL_GIFT_CONFIRM_ADDRESS
  );
  const [typeResponse, setTypeResponse] = useState(TYPE_RESPONSE.SUCCESS);

  const renderSubPage = () => {
    switch (giftExchangeStep) {
      case GIFT_EXCHANGE_STEP.PHYSICAL_GIFT_CONFIRM_ADDRESS:
        return (
          <PhysicalConfirmAddressV2
            setGiftExchangeStep={setGiftExchangeStep}
            onSuccess={handleSuccess}
            setTypeResponse={setTypeResponse}
          />
        );

      case GIFT_EXCHANGE_STEP.RESPONSE:
        return <Response typeResponse={typeResponse} setGiftExchangeStep={setGiftExchangeStep} />;

      case GIFT_EXCHANGE_STEP.GIFT_DETAIL:
        navigate(-1);
        return null;

      default:
        break;
    }
  };

  return <>{renderSubPage()}</>;
};
