const FeatureBlock = ({info, ...props}) => {
  return (
    <>
      {info?.isShow && (
        <div
          className="flex flex-col items-center justify-center bg-white shadow-small rounded-lg"
          onClick={info?.onClick || null}
          {...props}
        >
          <div className="mt-1">
            <img src={info?.src} alt="" />
          </div>
          <div className="mt-2 text-primary font-semibold leading-medium font-title">
            {info?.text || ''}
          </div>
        </div>
      )}
    </>
  );
};

export default FeatureBlock;
