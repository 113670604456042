import {requestApi} from 'helpers/apiUtils';
import {
  LOCATION,
  ORDER,
  GIFT,
  USER,
  PRODUCT,
  LOYALTY,
  GIFT_EXCHANGE,
  RECIPIENT,
  DELIVERY,
} from './api-definitions';
import {API_METHOD, SOURCE_CLS} from 'constants/index';

// LOCATION
export const getProvinceApi = () => {
  return requestApi({
    endpoint: LOCATION.getProvinceUrl,
    method: API_METHOD.GET,
  });
};

export const getDistrictWardApi = (parentId) => {
  return requestApi({
    endpoint: LOCATION.getDistrictWardUrl(parentId),
    method: API_METHOD.GET,
  });
};

// ORDER
export const getListGiftOrderApi = (tabName = 'ALL', userId) => {
  return requestApi({
    endpoint: ORDER.getListOrderByStatusTabUrlV4(userId),
    method: API_METHOD.GET,
    // params: {tabName: tabName},
  });
};

// GIFT
export const getGiftCategoriesApi = (params) => {
  return requestApi({
    endpoint: GIFT.getGiftCategoriesUrl,
    method: API_METHOD.GET,
    params: params,
  });
};

export const getGiftByIdApi = (id) => {
  return requestApi({
    endpoint: GIFT.getGiftByIdUrlV4(id),
    method: API_METHOD.GET,
  });
};

// PRODUCT
export const getCheckQRCode = (code) => {
  return requestApi({
    endpoint: PRODUCT.getCheckQRCode(code),
    method: API_METHOD.GET,
  });
};
export const getCheckSKUApi = (code) => {
  return requestApi({
    endpoint: PRODUCT.getCheckSKU(code),
    method: API_METHOD.GET,
  });
};
export const postSpoonCodeApi = (params) => {
  return requestApi({
    endpoint: PRODUCT.postSpoonCode,
    method: API_METHOD.POST,
    body: params,
  });
};

export const presignedUrlApi = () => {
  return requestApi({
    endpoint: PRODUCT.presignedUrl,
    method: API_METHOD.POST,
    body: {type: 'png', rootFolder: 'WEBAPP'},
  });
};
export const userRequestHistoryPointApi = (params) => {
  return requestApi({
    endpoint: PRODUCT.userRequestHistoryPoint,
    method: API_METHOD.POST,
    body: params,
  });
};

// USER
export const postUserLoginApi = (params) => {
  return requestApi({
    endpoint: USER.postUserLoginUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

export const postSendSmsAuthApi = (params) => {
  return requestApi({
    endpoint: USER.postSendSmsUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

export const postVerifyOtpAuthApi = (params) => {
  return requestApi({
    endpoint: USER.postVerifyOtpUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

export const postRegisterCalosureApi = (params) => {
  return requestApi({
    endpoint: USER.postRegisterCalosureUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

export const postLoginCalosureApi = (params) => {
  return requestApi({
    endpoint: USER.postLoginCalosureUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

export const postUserLogoutApi = (params) => {
  return requestApi({
    endpoint: USER.postUserLogoutUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

// LOYALTY
export const postSendSmsApi = (params) => {
  return requestApi({
    endpoint: LOYALTY.postSendSmsUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

export const postVerifyOtpApi = (params) => {
  return requestApi({
    endpoint: LOYALTY.postVerifyOtpUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

export const getUserInfoApi = () => {
  return requestApi({
    endpoint: LOYALTY.getUserInfoUrl,
    method: API_METHOD.GET,
  });
};

export const getCheckBlockedScanApi = () => {
  return requestApi({
    endpoint: LOYALTY.getCheckBlockedScanUrl,
    method: API_METHOD.GET,
  });
};

export const getCheckPhoneNumberApi = (params) => {
  return requestApi({
    endpoint: LOYALTY.getCheckPhoneNumberUrl,
    method: API_METHOD.GET,
    params: params,
  });
};

export const getListGiftByUserApi = (params) => {
  // params = {page: 1, limit: 20}
  return requestApi({
    endpoint: LOYALTY.getListGiftByUserUrl,
    method: API_METHOD.GET,
    params: params,
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });
};

export const getGiftsByUserWithCategoryIdApi = (categoryId) => {
  return requestApi({
    endpoint: LOYALTY.getGiftByUserWithCategoryIdUrlV4(categoryId),
    method: API_METHOD.GET,
  });
};

export const getListHighlightGiftApi = (params) => {
  // params = {page: 1, limit: 20}
  return requestApi({
    endpoint: LOYALTY.getListHighlightGiftUrl,
    method: API_METHOD.GET,
    params: params,
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });
};

export const getNotificationEarningCoinApi = (params) => {
  // params = {page: 1, limit: 20, source: 'CLS'}
  return requestApi({
    endpoint: LOYALTY.getHistoryEarningCoinUrl,
    method: API_METHOD.GET,
    params: params,
  });
};

// GIFT_EXCHANGE
export const postExchangeEVoucherApi = (params) => {
  return requestApi({
    endpoint: GIFT_EXCHANGE.postExchangeEVoucherUrlV4,
    method: API_METHOD.POST,
    body: params,
    headers: {source: SOURCE_CLS},
  });
};

// RECIPIENT
export const putRecipientApi = (params) => {
  return requestApi({
    endpoint: RECIPIENT.putRecipientUrl,
    method: API_METHOD.PUT,
    body: params,
  });
};

export const getInfoDeliveryApi = () => {
  return requestApi({
    endpoint: DELIVERY.getInfoDelivery,
    method: API_METHOD.GET,
  });
};
export const postDeliveryApi = (params) => {
  return requestApi({
    endpoint: DELIVERY.postDelivery,
    method: API_METHOD.POST,
    body: params,
  });
};

export const postExchangePhysicalApi = (params) => {
  return requestApi({
    endpoint: GIFT_EXCHANGE.postExchangePhysicalV4,
    method: API_METHOD.POST,
    body: params,
  });
};
