export const CONTACT_INFO = {
  PHONE_NUMBER: '1900633559',
  ZALO: 'http://zalo.me/2622402316765084052?src=qr',
};

export const LINK_PROMOTION =
  'https://vitadairy.vn/ung-dung-vitadairy/ung-dung-vitadairy-doi-muong-nhan-qua.html';

export const LINK_TERMS = 'https://vitadairyvietnam.vn/terms/';

export const BUTTON_TEXT = {
  BACK: 'QUAY LẠI',
  GIFT_EXCHANGE: 'ĐỔI QUÀ',
  ON_HOMEPAGE: 'VỀ TRANG CHỦ',
  CONFIRM: 'XÁC NHẬN',
  CONTACT_CUSTOMER_CARE: 'LIÊN HỆ CSKH',
  CHANGE_GIFT_RECEIVING_ADDRESS: 'ĐỔI ĐỊA CHỈ NHẬN QUÀ',
  HISTORY_COIN: 'XEM LỊCH SỬ TÍCH XU',
  LOGOUT: 'ĐĂNG XUẤT',
  CONTINUE: 'TIẾP TỤC',
  COMPLETE: 'HOÀN TẤT',
  TERMS: 'ĐIỀU KHOẢN SỬ DỤNG',
  TRY_AGAIN: 'THỬ LẠI',
};
export const LoginStep = {
  LoginInput: 'LoginInput',
  OtpInput: 'OtpInput',
  ErrorCodeResponse: 'ErrorCodeResponse',
};
export const RegisterStep = {
  InstructionRegister: 'InstructionRegister',
  Register: 'Register',
};
export const RegisterDetailStep = {
  Gender: 'Gender',
  Name: 'Name',
  Address: 'Address',
};

export const TYPE_VIEW = {
  LIST: 'LIST',
  DETAIL: 'DETAIL',
  DELIVERY_POLICY: 'DELIVERY_POLICY',
};

export const EarningCoinStep = {
  Intro: 'Intro',
  ScanQr: 'ScanQr',
  InputQr: 'InputQr',
  ErrorCode: 'ErrorCode',
  ProductInfo: 'ProductInfo',
  InputSpoonCode: 'InputSpoonCode',
  SuccessEarningCoin: 'SuccessEarningCoin',
};

export const ErrorCode = {
  RestrictedAccount: 'error.blocked_account_when_scan_failed',
  LimitedOtp: 'LimitedOtp',
  QrCodeCharactersNotCorrect: 'SB_QR_ERROR',
  QrCodeUsed: 'SB_QR_USED',
  QrCodeNotExisted: 'SB_QR_NOEXIST',
  QrCodeError: 'SB_QR_UNMATCH',
  QrCodeExpired: 'SB_QR_OUTDATE',
  ProductError: 'ProductError',
  SpoonCodeUsed: 'SB_SPOON_USED',
  SpoonCodeInvalid: 'SB_SPOON_NOEXIST',
  SpoonCodeNotCorrectProduct: 'SB_SPOON_UNMATCH_BRAND',
  SpoonCodeNotCorrectProductWeight: 'SB_SPOON_UNMATCH_WEIGHT',
  SpoonCodeNotCorrectWithQrCode: 'SB_DATE_1722',
  SpoonCodeNotCorrectWithQrCodeAfter22: 'SB_DATE_AFTER722',
  SpoonCodeNotCorrectWithQrCodeAfter23: 'SB_DATE_AFTER223',
  SpoonCodeLimited: 'SpoonCodeLimited',
  SpoonCodeInputFiveTimes: 'SB_BLOCK_SAMEQR',
  SpoonCodeInputErrorManyTimes: 'SB_BLOCK_SCANQR',
  ProductExpiredToEarningCoin: 'SB_EXPIRE',
  EarningCoinLimited: 'SB_OVERADDPOINT',
  EarningCoinError: 'SB_DEFAULT_ERROR',
  SystemOverloaded: 'SystemOverloaded',
  SystemMaintenance: 'SystemMaintenance',
  BlockedEarningCoin: 'BlockedEarningCoin',
  BlockedSupportEarningCoin: 'BlockedSupportEarningCoin',
  OtpIncorrect: 'OTP_INCORRECT',
  QrCodeBlock: 'SB_BLOCK_ADDPOINT',
  OtpNotExist: 'error.otp_not_exist',
  OtpWrong: 'error.otp_wrong',
  AddEmptyError: 'SB_ADD_EMPTY',
};

export const SupportEarningCoinStep = {
  EarningCoinOptions: 'EarningCoinOptions',
  Intro: 'Intro',
  Image: 'Image',
  PreviewImage: 'PreviewImage',
  Error: 'Error',
  Success: 'Success',
};

export const ImageStep = {
  QR: 'QR',
  Spoon: 'Spoon',
};

export const GENDER_LIST = [
  {
    value: 1,
    label: 'Ông',
  },
  {
    value: 2,
    label: 'Bà',
  },
  {
    value: 3,
    label: 'Cô',
  },
  {
    value: 4,
    label: 'Chú',
  },
  {
    value: 5,
    label: 'Anh',
  },
  {
    value: 6,
    label: 'Chị',
  },
];

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'accessToken',
};

export const CODE_KEY = {
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  ERROR_NETWORK: 'ERROR_NETWORK',
  TIME_OUT: 408,
  UNAUTHORIZED_STATUS: 401,
  NOT_INTERNET: 'NOT_INTERNET',
  UNDEFINED: 'UNDEFINED',
  UNKNOWN: 'UNKNOWN',
};

export const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const API_SUCCESS_STATUS = 1000;

export const CATEGORY_ID = {
  // SPECIAL_GIFT: Number(process.env.REACT_APP_CATEGORY_ID_SPECIAL_GIFT),
  // ALL_GIFT: Number(process.env.REACT_APP_CATEGORY_ID_ALL_GIFT),
  // HOT_GIFT: Number(process.env.REACT_APP_CATEGORY_ID_HOT_GIFT),
  SPECIAL_GIFT: process.env.REACT_APP_CATEGORY_ID_SPECIAL_GIFT?.split(',')?.map((num) =>
    Number(num)
  ),
  ALL_GIFT: process.env.REACT_APP_CATEGORY_ID_ALL_GIFT?.split(',')?.map((num) => Number(num)),
  HOT_GIFT: process.env.REACT_APP_CATEGORY_ID_HOT_GIFT?.split(',')?.map((num) => Number(num)),
  NEW_SPECIAL_GIFT: process.env.REACT_APP_CATEGORY_ID_NEW_SPECIAL_GIFT?.split(','),
  NEW_ALL_GIFT: process.env.REACT_APP_CATEGORY_ID_NEW_ALL_GIFT?.split(','),
  NEW_HOT_GIFT: process.env.REACT_APP_CATEGORY_ID_NEW_HOT_GIFT?.split(','),
};

export const SEND_OTP_SOURCE = {
  REGISTER: 'REGISTER',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  EXCHANGE_GIFT: 'EXCHANGE_GIFT',
  REGISTER_CALOSURE: 'register_calosure',
  LOG_IN_CALOSURE: 'log_in_calosure',
};

export const CODE_LAST_NAME_CALOSURE = 'CLS';

export const SOURCE_CLS = 'CLS';
