import {LoginContext} from 'App';
import {getInfoDeliveryApi, postDeliveryApi} from 'apis/index';
import {API_SUCCESS_STATUS} from 'constants/index';
import {GIFT_EXCHANGE_STEP, TYPE_RESPONSE} from 'constants/info-giftpage';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import ConfirmAddress from './address/confirmAddress';
import InputAddress from './address/inputAddress';

const checkHaveAddress = (address) => {
  return address?.address && address?.districtId && address?.wardId && address?.provinceId;
};

const PhysicalConfirmAddressV2 = ({onSuccess, setGiftExchangeStep, setTypeResponse}) => {
  const {userInfo, setIsLoading, isLoading} = useContext(LoginContext);

  const [receivedAddress, setReceivedAddress] = useState({
    name: '',
    provinceId: '',
    districtId: '',
    wardId: '',
    address: '',
    phoneNumber: '',
  });
  const [isConfirmedAddress, setIsConfirmedAddress] = useState(null);
  const checkAddress = useRef(null);

  const handlePostRecipient = useCallback(async (data) => {
    if (data) {
      let params = {
        address: data?.address,
        districtId: data?.districtId,
        name: data?.name,
        phoneNumber: data?.phoneNumber,
        provinceId: data?.provinceId,
        wardId: data?.wardId,
        id: data?.id,
      };
      setIsLoading(true);
      try {
        const response = await postDeliveryApi(params);
        if (response?.meta?.status === API_SUCCESS_STATUS) {
          await onSuccess?.();
        } else {
          setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
          setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
          toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
            autoClose: 2000,
            position: 'top-center',
          });
        }
      } catch (error) {
        setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
        setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
        toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const getRecipientData = async () => {
      setIsLoading(true);
      try {
        const response = await getInfoDeliveryApi();
        if (response?.response) {
          setReceivedAddress(response?.response);
          setIsConfirmedAddress(false);

          if (checkHaveAddress(response?.response)) {
            onSuccess?.();
          }
        } else {
          let initAdress = {
            ...receivedAddress,
            name: userInfo?.name,
            provinceId: userInfo?.provinceId,
            provinceName: userInfo?.provinceName,
            districtId: userInfo?.districtId,
            districtName: userInfo?.districtName,
            wardId: userInfo?.wardId,
            wardName: userInfo?.wardName,
            phoneNumber: userInfo?.phoneNumber,
            address: userInfo?.address,
          };
          if (checkHaveAddress(initAdress)) {
            onSuccess?.();
          }
          setReceivedAddress(initAdress);
        }
      } catch (error) {
        setTypeResponse(TYPE_RESPONSE.ERROR_GENERAL);
        setGiftExchangeStep(GIFT_EXCHANGE_STEP.RESPONSE);
        toast.error('Hệ thống đang bảo trì, vui lòng thử lại sau.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
      setIsLoading(false);
    };
    getRecipientData();
  }, [userInfo]);

  const handleClickBack_InputAddress = useCallback(() => {
    if (isConfirmedAddress === null) {
      setGiftExchangeStep(GIFT_EXCHANGE_STEP.GIFT_DETAIL);
    }
  }, [isConfirmedAddress]);

  const handleClickConfirm_ConfirmAddress = useCallback(async () => {
    await handlePostRecipient(receivedAddress);
  }, [receivedAddress]);

  return (
    <div>
      {isLoading && <div className={'absolute inset-0 z-10 bg-white h-[100vh]'} />}
      <InputAddress
        receivedAddress={receivedAddress}
        setReceivedAddress={setReceivedAddress}
        isConfirmedAddress={isConfirmedAddress}
        handleClickConfirm={handleClickConfirm_ConfirmAddress}
        handleClickBack={handleClickBack_InputAddress}
      />
    </div>
  );
};

export default PhysicalConfirmAddressV2;
