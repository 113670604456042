import React from 'react';
import {CircularProgress} from '@mui/material';
import LogoVita from 'images/logo-single.png';

const Spinner = () => {
  return (
    <>
      <div className="h-screen bg-background flex justify-center items-center">
        <div className="flex flex-col gap-2 items-center">
          <div className="w-[180px]">
            <img src={LogoVita} alt="Logo Vita Dairy" />
          </div>
          <CircularProgress />
        </div>
      </div>
    </>
  );
};

export default React.memo(Spinner);
