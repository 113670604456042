// import {useContext, useEffect, useCallback} from 'react';
// import {useNavigate} from 'react-router-dom';
import Header from 'components/common/header';
import VitaButton from 'components/common/VitaButton';
import {BUTTON_TEXT} from 'constants/index';
import {GIFT_EXCHANGE_STEP} from 'constants/info-giftpage';

const ConfirmExchange = ({data, setGiftExchangeStep, handleConfirmExchange, isExchangeLoading}) => {
  return (
    <>
      <div className="bg-background">
        <Header isBackground />
        <div className="px-4">
          <div className="mt-10 text-center text-2xl font-semibold leading-medium text-black">
            XÁC NHẬN ĐỔI QUÀ
          </div>
          <div className="mt-4 mb-10 text-center text-primary font-semibold text-2xl leading-medium">
            Bạn có muốn dùng{' '}
            <span className="font-extrabold">
              {data?.point || data?.point === 0 ? data?.point : ''} xu
            </span>{' '}
            để đổi <span>{data?.name || ''}</span> không?
          </div>
          <div className="px-2">
            <div>
              <VitaButton onClick={handleConfirmExchange} disabled={isExchangeLoading}>
                {BUTTON_TEXT.CONFIRM}
              </VitaButton>
            </div>
            <div className="my-6">
              <VitaButton
                onClick={() => setGiftExchangeStep(GIFT_EXCHANGE_STEP.GIFT_DETAIL)}
                isOutlined
                disabled={isExchangeLoading}
              >
                {BUTTON_TEXT.BACK}
              </VitaButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmExchange;
